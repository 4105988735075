<template>
  <div class="main">
    <div class="header">
      <div class="project-change">
        <p class="project-name">项目选择</p>
        <div class="header-select">
          <el-select
            v-model="projectName"
            placeholder="所有项目"
            class="select-project"
            @change="changeProject($event)"
          >
            <el-option
              v-for="item in projectList"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
              @click="resetbtnShow == true"
              style="color: rgb(195, 226, 249)"
            ></el-option>
          </el-select>
        </div>
        <p class="resetBtn" @click="resetProject()" v-show="resetbtnShow">
          重置
        </p>
        <div>
          <el-tooltip
            class="item"
            effect="dark"
            content="AI智能问答"
            placement="right-start"
          >
              <img
              @click="routerAI()"
              style="width: 2.5rem; border-radius: 50%; border-color: black;margin-left: 5px;"
              src="../../assets/drive/ai.jpg"
              alt=""
            />
          </el-tooltip>
          <el-popover placement="bottom" width="50" trigger="hover">
            <p>AI智能问答</p>
            
          </el-popover>
        </div>
      </div>
      <div class="title">智慧平台数据驾驶舱</div>
      <div class="time">{{ time }}</div>
      <div class="avatar">
        <el-popover
          placement="top"
          width="160"
          v-model="visible"
          trigger="hover"
        >
          <p style="padding: 10px 0px">确定要退出登录吗？</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="cancelLogout()"
              >取消</el-button
            >
            <el-button type="primary" size="mini" @click="logout()"
              >确定</el-button
            >
          </div>
          <!-- <img src="../../assets/home/avatar.png" alt="" slot="reference" /> -->
          <img
            style="width: 2.5rem; border-radius: 50%; border-color: black"
            src="../../assets/home/logo.jpg"
            alt=""
            slot="reference"
          />
        </el-popover>
      </div>
    </div>
    <div class="left" style="display: none;">
      <p class="left-title">在线设备数</p>
      <div class="left-content">
        <div class="left-content-data" @click="getOnlineDev()">
          <p class="devNumber">N台</p>
        </div>
        <!-- <div class="data-common">
          总的在线设备
          <span class="data-common-data" style="color: rgb(54, 240, 206); font-size: 1.24vw; margin: auto">{{ devNumber
          }}</span>
        </div> -->
        <div class="left-content-footer"></div>
      </div>
    </div>
    <!-- <div> -->
    <baidu-map
      class="map"
      :center="map.center"
      id="map"
      :zoom="map.zoom"
      :options="{enable3D: true}"
      @ready="handler"
    >
      <!--缩放-->
      <!-- <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation> -->
      <!--定位-->
      <!-- <bm-geolocation
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        :showAddressBar="true"
        :autoLocation="true"
      ></bm-geolocation> -->
      <!--点-->
      <bm-marker
        v-for="(marker, i) of markers"
        :key="i"
        :position="{ lng: marker.longitude, lat: marker.latitude }"
        @click="lookDetail(marker, i)"
      >
        <bm-info-window
          title="设备详情"
          :position="{ lng: marker.longitude, lat: marker.latitude }"
          :show="marker.showFlag"
          @close="infoWindowClose(marker)"
          @open="infoWindowOpen(marker)"
        >
          <div>
            <span v-if="marker.city && !marker.district">{{
              marker.city
            }}</span>
            <span v-if="marker.district">{{ marker.district }}</span>
            <span>共有设备{{ marker.deviceNumber }}台</span>
          </div>
          <div>经度：{{ marker.longitude }}</div>
          <div>纬度：{{ marker.latitude }}</div>
        </bm-info-window>
        <bm-label
          :content="String(marker.deviceNumber)"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(0, 0, 0, 0)',
            width: '15px',
            textAlign: 'center',
          }"
          :offset="{ width: 0, height: 0 }"
        />
      </bm-marker>
    </baidu-map>
    <!-- </div> -->
    <div class="right" style="display: none;">
      <p class="right-title">报警信息</p>
      <div class="right-content">
        <div class="no-content-data-mes td" v-if="devNumber === 0">
          <span
            class="mes-word"
            style="color: rgb(241, 96, 96)"
            v-text="'暂无设备报警信息'"
          ></span>
        </div>
        <vue-seamless-scroll
          v-else
          :data="alarmList"
          :class-option="defaultOption"
          class="seamless-scroll"
        >
          <ul class="right-content-data">
            <li v-for="(item, index) in alarmList" :key="index">
              <span class="content-data-title" v-text="item.deviceName"></span>
              <div class="content-data-box">
                <div class="content-data-mes td">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      item.alarmTime +
                      ' ' +
                      item.alarmLevel +
                      '级警报' +
                      ' ' +
                      item.alarmType
                    "
                    placement="top"
                  >
                    <span
                      class="mes-word"
                      style="color: rgb(241, 96, 96)"
                      v-text="
                        item.alarmTime +
                        ' ' +
                        item.alarmLevel +
                        '级警报' +
                        ' ' +
                        item.alarmType
                      "
                    ></span>
                  </el-tooltip>
                </div>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
      <!-- <p class="right-title-img">实时运维画面</p>
      <div class="picBox">
        <div v-if="details.length > 0">
          <vue-preview :slides="details" class="preview" style="display:inline-block;"></vue-preview>
        </div>
        <div v-else class="preview noimg">
          暂无项目图片
        </div>

      </div> -->
    </div>
    <div class="footer"></div>
    <!-- 放大图 -->
    <!-- <div id="bigImage" v-show="showbigImage" style="background: rgba(0,0,0,0.8);">
      <img @click="showbigImageClick()" style="width:600px;height: 400px;margin: 300px 300px;" src="../../assets/home/alarm.png" alt="">
    </div> -->
  </div>
</template>

<script>
import effect from "../../../public/effect";
export default {
  name: "demo",
  data() {
    return {
      // 重置项目按钮是否出现
      resetbtnShow: false,
      showbigImage: false,
      // 场景图片数组
      details: [],
      projectName: "所有项目",
      selectedData: "",
      // 项目id
      projectId: null,
      map: {
        center: { lng: 105.52801179885864, lat: 31.75955126285553 },
        zoom: 5,
        show: true,
        dragging: true,
      },
      // 地图标记点
      markers: [],
      infoWindow: {},
      mapStyle: {
        // 百度地图主题编辑器
        // https://developer.baidu.com/map/custom/
        styleJson: [
          {
            featureType: "road",
            elementType: "geometry",
            stylers: {
              color: "#eee",
            },
          },
        ],
      },
      time: "",
      setTime: "",
      projectList: [{ id: null, projectName: "" }],
      devNumber: null,
      visible: false,
      alarmList: [
        {
          deviceName: "",
          alarmType: "",
          alarmLevel: null,
          alarmTime: "",
        },
      ],
      type: "",
    };
  },
  computed: {
    // 右侧报警滚动
    defaultOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: this.alarmList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },

  created() {
    let that = this;
    // @ts-ignore
    this.setTime = setInterval(() => {
      that.Date();
    }, 1000);
    // this.Date();

    // 从本地存储中取出选中的数据
    // @ts-ignore
    if (localStorage.getItem("roleName") == "超级管理员") {
      if (sessionStorage.getItem("pathskip") == 1) {
        localStorage.removeItem("projectID");
        localStorage.removeItem("selectedData");
        this.projectName = "";
      } else {
        this.selectedData = JSON.parse(localStorage.getItem("selectedData"));
        // console.log(this.selectedData)
        // 如果取出的数据存在，则将其赋值给el-select
        if (this.selectedData) {
          this.projectName = this.selectedData;
        } else {
          this.projectName = "";
        }
      }
    } else {
      this.selectedData = JSON.parse(localStorage.getItem("selectedData"));

      // 如果取出的数据存在，则将其赋值给el-select
      if (this.selectedData) {
        this.projectName = this.selectedData;
      }
    }
    // 获取城市设备数
    this.getCityNum();
    // 获取总设备数
    this.getTotalDevice();
  },
  mounted() {
    if (this.projectName != "") {
      this.resetbtnShow = true;
    }
    window.addEventListener("resize", function () {
      window.location.reload();
    });
    // 初始化报警信息
    this.getAlarmData();
    // 初始化用户项目信息
    this.getProjectData();
    // 放大场景图片缩略图
    this.getThirdHaikEventList();
  },
  methods: {
    // 跳转到AI问答页面
    routerAI() {
      this.$router.push("/AIClass");
    },
    // 重置项目
    resetProject() {
      this.resetbtnShow = false;
      localStorage.removeItem("projectID");
      localStorage.removeItem("selectedData");
      this.projectName = "";
      // 初始化报警信息
      this.getAlarmData();
      // 初始化用户项目信息
      this.getProjectData();
      // 放大场景图片缩略图
      this.getThirdHaikEventList();
      // 获取城市设备数
      this.getCityNum();
      // 获取总设备数
      this.getTotalDevice();
    },

    // 放大图片
    showbigImageClick() {
      this.showbigImage = !this.showbigImage;
    },
    // 放大缩略图
    getThirdHaikEventList() {
      let arr;
      if (localStorage.getItem("projectID") == null) {
        arr = "";
      } else {
        arr = localStorage.getItem("projectID");
      }
      var param = {
        pageNum: 1,
        pageSize: 20,
        projectId: arr,
        // deviceId:17
      };
      this.$post("/devicePhoto/pageList", param)
        .then((res) => {
          // 主要参数
          // console.log(res);
          if (res.code == 1) {
            let data = res.data.list;
            if (data && Object.keys(data).length) {
              data.forEach((item) => {
                item.w = 600; //设置以大图浏览时的宽度
                item.h = 400; //设置以大图浏览时的高度
                item.src = item.photoUrl; //大图
                item.msrc = item.photoUrl; //小图
              });
            }
            this.details = data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 地图初始化
    handler({ BMap, map }) {
      // console.log(BMap, map);
      let that = this;
      map.setMinZoom(5);
     
      // 只显示中国区域
      effect.drawBoundary(map);

      // 控制颜色
      let mapStyle = { style: "light" };

      map.setMapStyle(mapStyle);
      map.getContainer().style.backgroundColor = "#091934";
      map.setMapStyle({
        styleJson: [
          {
            featureType: "water",
            elementType: "all",
            stylers: {
              color: "#044161",
            },
          },
          {
            featureType: "land",
            elementType: "all",
            stylers: {
              color: "#091934",
            },
          },
          {
            featureType: "boundary",
            elementType: "geometry",
            stylers: {
              color: "#064f85",
            },
          },
          {
            featureType: "railway",
            elementType: "all",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "highway",
            elementType: "geometry",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "highway",
            elementType: "geometry.fill",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "highway",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "arterial",
            elementType: "geometry",
            stylers: {
              color: "#004981",
              lightness: -39,
            },
          },
          {
            featureType: "arterial",
            elementType: "geometry.fill",
            stylers: {
              color: "#00508b",
            },
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "green",
            elementType: "all",
            stylers: {
              color: "#056197",
              visibility: "on",
            },
          },
          {
            featureType: "subway",
            elementType: "all",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "manmade",
            elementType: "all",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "local",
            elementType: "all",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "arterial",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "boundary",
            elementType: "geometry.fill",
            stylers: {
              color: "#029fd4",
            },
          },
          {
            featureType: "building",
            elementType: "all",
            stylers: {
              color: "#1a5787",
            },
          },
          {
            featureType: "label",
            elementType: "all",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: {
              color: "#ffffff",
            },
          },
          {
            featureType: "poi",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#1e1c1c",
            },
          },
          {
            featureType: "administrative",
            elementType: "labels",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "road",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: {
              color: "#857f7f",
              visibility: "on",
            },
          },
          {
            featureType: "all",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#000000",
              visibility: 0,
            },
          },
          {
            featureType: "all",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: {
              // color: "#2da0c6",
              color: "#ffffff",
              visibility: "on",
              fontSize: 30,
            },
          },
        ],
      });

      // 鼠标缩放
      map.enableScrollWheelZoom(true);
      // 点击事件获取经纬度
      // map.addEventListener("click", function (e) {
      //   console.log(e.point.lng, e.point.lat);
      //   console.log(that.map.zoom);
      // });
      map.addEventListener("zoomend", function () {
        let zoom = map.getZoom(); // 获取缩放级别
        // 其余操作
        that.map.zoom = zoom;
        console.log(zoom);
        if (that.map.zoom <= 9) {
          // console.log(zoom);
          that.getCityNum();
        } else if (that.map.zoom > 9 && that.map.zoom < 12) {
          // console.log(zoom);
          that.getRegionNum();
        } else if (that.map.zoom >= 12) {
          // console.log(zoom);
          that.getBuildingNum();
        }
      });
    },
    // 获取总设备数
    getTotalDevice() {
      let arr = [];
      if (localStorage.getItem("projectID") == null) {
        arr = [];
      } else {
        arr = [localStorage.getItem("projectID")];
      }
      var param = {
        projectList: arr,
      };
      this.$post("/device/list", param)
        .then((res) => {
          // 主要参数
          // console.log(res);
          if (res.code == 1) {
            this.devNumber = res.data.deviceNumber;
            // console.log(this.devNumber);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 获取城市设备数
    getCityNum() {
      // console.log(localStorage.getItem("projectID"))
      let arr = [];
      if (localStorage.getItem("projectID") == null) {
        arr = [];
      } else {
        arr = [localStorage.getItem("projectID")];
      }
      var param = {
        projectList: arr,
      };
      this.$post("/device/deviceCityNumber", param)
        .then((res) => {
          // 主要参数
          // console.log("城市");
          // console.log(res);
          if (res.code == 1) {
            this.markers = res.data;
            this.markers.forEach((value) => {
              value["showFlag"] = false;
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 获取区设备数
    getRegionNum() {
      let arr = [];
      if (localStorage.getItem("projectID") == null) {
        arr = [];
      } else {
        arr = [localStorage.getItem("projectID")];
      }
      var param = {
        projectList: arr,
      };
      this.$post("/device/deviceDistrictNumber", param)
        .then((res) => {
          // 主要参数
          console.log("区域");
          console.log(res);
          if (res.code == 1) {
            this.markers = res.data;
            this.markers.forEach((value) => {
              value["showFlag"] = false;
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 获取大厦设备数
    getBuildingNum() {
      let arr = [];
      if (localStorage.getItem("projectID") == null) {
        arr = [];
      } else {
        arr = [localStorage.getItem("projectID")];
      }
      var param = {
        projectList: arr,
        deviceState: 0,
      };
      // console.log(param);
      this.$post("/device/deviceBuildNumber", param)
        .then((res) => {
          // 主要参数
          console.log("大厦");
          console.log(res.data);
          if (res.code == 1) {
            this.markers = res.data;
            this.markers.forEach((value) => {
              value["showFlag"] = false;
            });
            console.log(this.markers);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 点击点坐标赋值
    lookDetail(marker, i) {
      console.log(marker, i);
      // this.map.zoom = 8;
      // console.log(this.markers[i])
      // console.log(this.map.zoom);
      var that = this;
      this.$router.push({
        path: "/CityDevice",
        query: {
          // marker: JSON.stringify(that.markers[i]),
          type: "map",
        },
      });
      localStorage.setItem("marker", JSON.stringify(that.markers[i]));
    },
    // 关闭弹窗
    infoWindowClose(marker) {
      marker.showFlag = false;
    },
    // 打开弹窗
    infoWindowOpen(marker) {
      marker.showFlag = true;
    },
    // 获取时间戳
    Date() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hours: nowDate.getHours(),
        minutes: nowDate.getMinutes(),
        seconds: nowDate.getSeconds(),
      };

      const newmonth = date.month >= 10 ? date.month : "0" + date.month;
      const newday = date.date >= 10 ? date.date : "0" + date.date;
      const newminutes = date.minutes >= 10 ? date.minutes : "0" + date.minutes;
      const newseconds = date.seconds >= 10 ? date.seconds : "0" + date.seconds;

      this.time =
        date.year +
        "年" +
        newmonth +
        "月" +
        newday +
        "日" +
        date.hours +
        ":" +
        newminutes +
        ":" +
        newseconds;
    },
    // 选择项目
    changeProject(value) {
      this.resetbtnShow = true;
      this.selectedData = value;
      // 将选中的数据保存到本地存储中
      localStorage.setItem("selectedData", JSON.stringify(this.selectedData));
      this.projectId = value;
      // 保存projectID
      localStorage.setItem("projectID", value);
      console.log(value);
      this.$nextTick(function () {
        // 放大场景图片缩略图
        this.getThirdHaikEventList();
      });
      this.getTotalDevice();
      this.getCityNum();
      this.getAlarmData();
      this.map.zoom = 5;
    },
    // 退出登录
    logout() {
      //   window.sessionStorage.clear();
      //   this.clearCookie();
      this.$get("/user/logout")
        .then((res) => {
          if (res.code == 1) {
            this.$router.push("/Login");
            this.$message({
              type: "success",
              message: "登出成功!",
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    cancelLogout() {
      this.visible = false;
      this.$message({
        type: "info",
        message: "已取消登出",
      });
    },
    // 跳转在线设备
    getOnlineDev() {
      this.$router.push({
        path: "/CityDevice",
        query: { type: "dev" },
      });
    },
    // 获取报警信息
    getAlarmData() {
      let arr = [];
      if (localStorage.getItem("projectID") == null) {
        arr = [];
      } else {
        arr = [localStorage.getItem("projectID")];
      }
      let param = {
        pageNum: "1",
        pageSize: "10",
        projectList: arr,
      };
      this.$post("/alarm/pageList", param)
        .then((res) => {
          // console.log(res);
          this.alarmList = res.data.list;
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 获取项目信息
    getProjectData() {
      let param = {
        pageNum: "1",
        pageSize: "100",
        uid: localStorage.getItem("uid"),
      };
      this.$post("/userProject/pageList", param)
        .then((res) => {
          console.log('111')
          console.log(res.data.list);
          this.projectList = res.data.list;
        })
        .catch(() => {
          console.log("异常");
        });
    },
  },
  beforeDestroy() {
    if (this.setTime) {
      clearInterval(this.setTime);
    }
  },
};
</script>
<style>
/* // 图片大小模糊解决办法 */
img {
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

/* 缩略图放大功能 */
.picBox {
  background-color: black;
  width: 22vw;
  overflow: hidden;
  color: #fff;
}

.preview {
  width: 24vw;
  height: calc(78vh - 339px);
  overflow-y: scroll;
}

.noimg {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1.223vw;
  cursor: pointer;
}

.my-gallery {
  display: flex;
  flex-wrap: wrap;
}

.preview figure {
  float: left;
  width: 26%;
  /* height: 10vh; */
  margin: 1.5% 1%;
}

/* .pswp__button--share{
    display: none !important;
} */
.preview figure img {
  width: 100%;
  height: 75%;
  /* object-fit: none; */
}

.BMap_bubble_title {
  color: #fff;
  font-size: 18px;
  /*font-weight: bold;*/
  text-align: left;
  background: transparent !important;
}

.BMap_pop .BMap_top {
  background: #3f4358 !important;
  border: 0 !important;
}

.BMap_pop .BMap_center {
  width: 251px !important;
  border: 0 !important;
  background: #3f4358 !important;
}

.BMap_pop .BMap_bottom {
  border: 0 !important;
  background: #3f4358 !important;
}

.BMap_pop div:nth-child(3) {
  background: transparent !important;
}

.BMap_pop div:nth-child(3) div {
  border-radius: 7px;
  background: #3f4358 !important;
  border: 0 !important;
}

.BMap_pop div:nth-child(1) {
  border-radius: 7px 0 0 0;
  background: transparent !important;
  border: 0 !important;
}

.BMap_pop div:nth-child(1) div {
  background: #3f4358 !important;
}

.BMap_pop div:nth-child(5) {
  border-radius: 0 0 0 7px;
  background: transparent !important;
  border: 0 !important;
}

.BMap_pop div:nth-child(5) div {
  border-radius: 7px;
  background: #3f4358 !important;
}

.BMap_pop div:nth-child(7) {
  background: transparent !important;
}

.BMap_pop div:nth-child(7) div {
  border-radius: 7px;
  background: #3f4358 !important;
}

.BMap_pop div:nth-child(8) div {
  /*border-radius:7px;*/
  background: #3f4358 !important;
}

/*窗体阴影*/
.BMap_shadow div:nth-child(5) img {
  margin-left: -1100px !important;
}

.BMap_shadow div:nth-child(4) {
  width: 262px !important;
}
</style>
<style lang="scss" scoped>
// 放大图
#bigImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.main {
  width: 100vw;
  height: 100vh;
  background-color: #07080e;
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow: hidden;

  .header {
    position: absolute;
    height: 71px;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 1090px;
    // z-index: 2;
    opacity: 1;
    background-image: url(../../assets/drive/backimage-top.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .project-change {
      position: absolute;
      // width: 231px;
      height: 38px;
      left: 15px;
      top: 29px;
      display: flex;
      align-items: center;

      .project-name {
        margin: 0 8px;
        color: rgb(195, 226, 249);
        font-family: PingFang SC;
        font-size: 1.04vw;
        line-height: 26px;
        letter-spacing: 0px;
        cursor: pointer;
      }

      .header-select {
        box-sizing: border-box;
        vertical-align: middle;
        .select-project {
          ::v-deep .el-select,
          ::v-deep .el-input,
          ::v-deep .el-input__inner {
            background: rgb(13, 33, 40);
            border: 1px solid rgb(11, 166, 167);
            box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
            color: rgb(195, 226, 249);
            font-family: PingFang SC;
            border-radius: 7px;
            width: 8vw;
            ::placeholder {
              color: rgb(195, 226, 249);
            }
          }
        }
      }
      // 重置项目按钮
      .resetBtn {
        background: rgb(13, 33, 40);
        border: 1px solid rgb(11, 166, 167);
        box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
        color: rgb(195, 226, 249);
        font-family: PingFang SC;
        border-radius: 7px;
        display: inline-block;
        width: 4vw;
        height: 38px;
        line-height: 38px;
        text-align: center;
        margin-left: 1vw;
        font-size: inherit;
        margin: 0 8px;
      }
    }

    .title {
      height: 68px;
      cursor: pointer;
      background: linear-gradient(
        180deg,
        rgba(238, 238, 238, 1),
        rgba(113, 244, 236, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: PingFang SC;
      font-size: 2.23vw;
      font-weight: 500;
      line-height: 50px;
      letter-spacing: 8px;
      text-align: center;
    }

    .time {
      position: absolute;
      height: 26px;
      right: 75px;
      top: 39px;
      bottom: 1015px;
      cursor: pointer;
      color: rgb(195, 226, 249);
      font-family: PingFang;
      font-size: 1.04vw;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0px;
      text-align: center;
    }

    .avatar {
      position: absolute;
      height: 26px;
      right: 20px;
      top: 32px;
      bottom: 1015px;
    }
  }

  .left {
    position: absolute;
    z-index: 1;
    box-sizing: content-box;
    background: #07080e;
    width: 20vw;
    top: 80px;

    .left-title {
      width: 19vw;
      height: 50px;
      text-indent: 2em;
      cursor: pointer;
      background-image: url(../../assets/drive/online-device-num.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      color: rgb(255, 255, 255);
      font-family: PingFang SC;
      font-size: 1.223vw;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0px;
    }

    .left-content {
      text-align: center;
      z-index: 1;
      width: 18vw;
      height: calc(100vh - 195px);
      margin: 0 1vw;
      box-sizing: content-box;
      background: linear-gradient(
        90deg,
        rgb(21, 28, 28),
        rgb(17, 58, 69) 53.646%,
        rgb(21, 28, 28) 100%
      );
      border: 1px solid rgb(26, 52, 60);

      // 左上右上边框小竖条
      &::before {
        display: block;
        content: "";
        height: 0.75rem;
        position: absolute;
        left: 0.9vw;
        top: 50px;
        border-left: 2px solid #25fef6;
        border-top: 2px solid #25fef6;
      }

      &::after {
        display: block;
        content: "";
        height: 0.75rem;
        position: absolute;
        right: 0.9vw;
        top: 50px;
        border-right: 2px solid #25fef6;
        border-top: 2px solid #25fef6;
      }

      .left-content-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        // 左下右下边框小竖条
        &::before {
          display: block;
          content: "";
          height: 0.75rem;
          position: absolute;
          left: 0.9vw;
          bottom: 0px;
          border-left: 2px solid #25fef6;
          border-bottom: 2px solid #25fef6;
        }

        &::after {
          display: block;
          content: "";
          height: 0.75rem;
          position: absolute;
          right: 0.9vw;
          bottom: 0px;
          border-right: 2px solid #25fef6;
          border-bottom: 2px solid #25fef6;
        }
      }

      .left-content-data {
        height: 25vh;
        background-image: url(../../assets/drive/set-number.svg);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin: 22vh auto 0vh;

        .devNumber {
          color: #44e1ff;
          font-family: DIN Alternate;
          line-height: 25.24vh;
          letter-spacing: 0px;
          font-size: 1.65vw;
          cursor: pointer;
        }
      }

      .data-common {
        color: rgb(255, 255, 255);
        font-family: PingFang SC;
        font-size: 1.04vw;
        line-height: 29px;
        letter-spacing: 0px;
        cursor: pointer;
      }
    }
  }

  .right {
    position: absolute;
    z-index: 1;
    box-sizing: content-box;
    width: 20vw;
    height: calc(100vh - 150px);
    overflow: hidden;
    top: 80px;
    right: 0;
    background: #07080e;
    ul,
    li {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    // 报警信息标题
    .right-title {
      width: 20vw;
      height: 50px;
      text-indent: 2em;
      margin-right: 20px;
      background-image: url(../../assets/drive/online-device-num.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      color: rgb(255, 255, 255);
      font-family: PingFang SC;
      font-size: 1.223vw;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0px;
    }

    // 报警信息
    .right-content {
      width: 20vw;
      // height: 32vh;

      // margin-right: 2vw;
      .seamless-scroll {
        width: 18vw;
        height: 70vh;
        // height: 30vh;
        overflow: hidden;
        margin: 0 1vw;
      }

      .right-content-data {
        .content-data-title {
          color: rgb(255, 255, 255);
          font-family: PingFang SC;
          font-size: 1.014vw;
          line-height: 33px;
          letter-spacing: 1.375px;
        }

        .content-data-box {
          display: flex;
          box-sizing: border-box;
          border-radius: 30px;
          background: linear-gradient(
            270deg,
            rgba(150, 236, 236, 0),
            rgb(13, 13, 18) 42.022%,
            rgb(32, 45, 50) 75.999%,
            rgb(15, 14, 20) 97.505%
          );

          .content-data-mes {
            width: 100%;
            height: 30px;
            background-image: url(../../assets/drive/alarm-message.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            font-family: PingFang SC;
            letter-spacing: 0.625px;
            text-align: center;

            .mes-word {
              display: inline-block;
              // padding: 0 1vw;
             
              text-align: center;
              box-sizing: border-box;
              font-family: PingFang SC;
              font-size: 0.83vw;
              line-height: 30px;
              letter-spacing: 0.625px;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              cursor: pointer;
            }
          }
        }
      }

      .no-content-data-mes {
        width: 100%;
        height: 30px;
        background-image: url(../../assets/drive/alarm-message.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-family: PingFang SC;
        letter-spacing: 0.625px;
        text-align: center;

        .mes-word {
          display: inline-block;
          padding: 0 1vw;
          box-sizing: border-box;
          font-family: PingFang SC;
          font-size: 0.83vw;
          line-height: 30px;
          letter-spacing: 0.625px;
          width: 22vw;
          overflow: hidden;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }

    // 场景图片标题
    .right-title-img {
      width: 20vw;
      height: 50px;
      margin-top: 9px;
      text-indent: 2em;
      margin-right: 0px;
      background-image: url(../../assets/drive/online-device-num.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      color: rgb(255, 255, 255);
      font-family: PingFang SC;
      font-size: 1.223vw;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0px;
    }

    // 场景图片
    // .picBox {
    //   width: 22vw;
    //   background-color: #25fef6;
    //   height: calc(78vh - 189px);
    // }
  }

  .map {
    position: absolute;
    top: 71px;
    left: 0;
    right: 20vw;
    bottom: 2vw;
    width: 100vw;
    height: calc(100vh - 136px);
  }

  .footer {
    position: fixed;
    width: 100%;
    height: 65px;
    background-image: url(../../assets/drive/backimage-bottom.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: 0px;
  }
}
</style>
